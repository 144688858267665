import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import SimpleFeature from '../../components/Features/SimpleFeature/SimpleFeature';
import HeartIcon from '../../images/icons/heart.svg';
import StarIcon from '../../images/icons/star.svg';
import AwardIcon from '../../images/icons/award.svg';

const ValuesSection = () => {
  const intl = useIntl();
  const heading = intl.formatMessage({ id: 'about.values' });
  const subheading = intl.formatMessage({ id: 'about.valuesText' });

  const values = [
    {
      iconSVG: <AwardIcon className="h-8 w-8" />,
      title: intl.formatMessage({ id: 'about.valuesCard.0.value' }),
      description: intl.formatMessage({ id: 'about.valuesCard.0.description' }),
    },
    {
      iconSVG: <StarIcon className="h-8 w-8" />,
      title: intl.formatMessage({ id: 'about.valuesCard.1.value' }),
      description: intl.formatMessage({ id: 'about.valuesCard.1.description' }),
    },
    {
      iconSVG: <HeartIcon className="h-8 w-8" />,
      title: intl.formatMessage({ id: 'about.valuesCard.2.value' }),
      description: intl.formatMessage({ id: 'about.valuesCard.2.description' }),
    },
  ];

  return (
    <>
      <SimpleFeature heading={heading} subheading={subheading} cards={values} />
    </>
  );
};

export default ValuesSection;
