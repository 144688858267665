import React from 'react';
import FeatureContainer from '../FeatureContainer';
import FeatureHeading from '../FeatureHeading';
import FeatureSubheading from '../FeatureSubheading';
import SimpleFtCard from './SimpleFtCard';

const SimpleFeature = ({ heading, subheading, cards }) => {
  return (
    <FeatureContainer>
      <FeatureSubheading subheading={subheading} />
      <FeatureHeading heading={heading} />
      <div className="flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto">
        {cards.map((card, index) => (
          <SimpleFtCard card={card} key={index} />
        ))}
      </div>
    </FeatureContainer>
  );
};

export default SimpleFeature;
