import React from 'react';

const FeatureHeading = ({ heading }) => {
  return (
    <h2 className="text-4xl sm:text-5xl text-primary-black mt-5 font-black tracking-wide text-center">
      {heading}
    </h2>
  );
};

export default FeatureHeading;
