import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import SimpleWithImageFeature from '../../components/Features/SimpleWithImageFeature/SimpleWithImageFeature';
import GallerySection from '../services/GallerySection';

const MainTeamSection = () => {
  const intl = useIntl();

  const images = [
    {
      fileNameImage: 'dima-painter-team-1.jpg',
      service: 'Dima Painter Team Image',
    },
    {
      fileNameImage: 'dima-painter-team-2.jpg',
      service: 'Dima Painter Team Image',
    },
    {
      fileNameImage: 'dima-painter-team-3.jpg',
      service: 'Dima Painter Team Image',
    },
    {
      fileNameImage: 'dima-painter-team-4.jpg',
      service: 'Dima Painter Team Image',
    },
    {
      fileNameImage: 'dima-painter-team-5.jpg',
      service: 'Dima Painter Team Image',
    },
    {
      fileNameImage: 'dima-painter-team-6.jpg',
      service: 'Dima Painter Team Image',
    },
    {
      fileNameImage: 'dima-painter-team-7.jpg',
      service: 'Dima Painter Team Image',
    },
    {
      fileNameImage: 'dima-painter-team-8.jpg',
      service: 'Dima Painter Team Image',
    },
    {
      fileNameImage: 'dima-painter-team-9.jpg',
      service: 'Dima Painter Team Image',
    },
  ];

  const cards = [
    {
      imgSrc: 'dima-painter-test-1.jpg',
      name: intl.formatMessage({ id: 'about.teamMembers.0.name' }),
      position: intl.formatMessage({ id: 'about.teamMembers.0.position' }),
    },
    {
      imgSrc: 'dima-painter-test-2.jpg',
      name: intl.formatMessage({ id: 'about.teamMembers.1.name' }),
      position: intl.formatMessage({ id: 'about.teamMembers.1.position' }),
    },
    {
      imgSrc: 'dima-painter-test-3.jpg',
      name: intl.formatMessage({ id: 'about.teamMembers.2.name' }),
      position: intl.formatMessage({ id: 'about.teamMembers.2.position' }),
    },
  ];
  return (
    <>
      <SimpleWithImageFeature
        heading={intl.formatMessage({ id: 'about.teamDescription' })}
        subheading={intl.formatMessage({ id: 'about.teamTitle' })}
        cards={cards}
      />
      <GallerySection
        title={intl.formatMessage({ id: 'about.teamText' })}
        images={images}
        textColor="text-primary-navy"
      />
    </>
  );
};

export default MainTeamSection;
