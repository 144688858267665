import React from 'react';

const SimpleFtCard = ({ card }) => {
  return (
    <div className="lg:w-1/3 max-w-xs">
      <div className="flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded">
        <div className="text-center rounded-full p-4 text-primary-navy bg-gray-100">
          {card.iconSVG}
        </div>
        <span className="mt-4 font-bold text-xl leading-none">
          {card.title}
        </span>
        <p className="mt-4 text-lg font-medium text-gray-600">
          {card.description}
        </p>
      </div>
    </div>
  );
};

export default SimpleFtCard;
