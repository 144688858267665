import React from 'react';

const FeatureContainer = ({ children }) => {
  return (
    <div className="relative">
      <div className="max-w-screen-xl p-8 mx-auto pt-20 pb-10">{children}</div>
    </div>
  );
};

export default FeatureContainer;
