import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import MisionSection from '../sections/about/MisionSection';
import ValuesSection from '../sections/about/ValuesSection';
import VisionSection from '../sections/about/VisionSection';
import SEO from '../components/seo';
import MainTeamSection from '../sections/about/MainTeamSection';

const AboutPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'seo.about.title' })}
        description={intl.formatMessage({ id: 'seo.about.description' })}
      />
      <MisionSection />
      <VisionSection />
      <ValuesSection />
      <MainTeamSection />
    </Layout>
  );
};

export default AboutPage;
