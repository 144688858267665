import React from 'react';
import Image from '../../Image';

const FeatureImgCard = ({ card }) => {
  return (
    <div className="md:w-1/2 lg:w-1/3 max-w-sm">
      <div className="flex flex-col  items-center  text-center sm:text-left h-full mx-4 px-2 py-8">
        <div className="w-64 h-64 ">
          <Image fileName={card.imgSrc} alt="texto" className="rounded-3xl" />
        </div>
        <div className="flex flex-col items-center mt-6">
          <span className="uppercase font-bold tracking-widest text-xs text-primary-navy">
            {card.position}
          </span>
          <span className="mt-1 text-xl font-medium text-gray-900">
            {card.name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FeatureImgCard;
