import React from 'react';

const FeatureSubheading = ({ subheading }) => {
  return (
    <h5 className="font-bold uppercase text-center mb-3 bg-primary-navy text-primary-white px-4 transform -skew-x-12">
      {subheading}
    </h5>
  );
};

export default FeatureSubheading;
