import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import MainFeature from '../../components/Features/MainFeature/MainFeature';

const MisionSection = () => {
  const intl = useIntl();
  const heading = intl.formatMessage({ id: 'about.mision' });
  const subheading = intl.formatMessage({ id: 'about.misionText' });
  return (
    <>
      <MainFeature
        heading={heading}
        subheading={subheading}
        textOnLeft={true}
        image={{
          fileName: 'dima-painter-mision.jpg',
          alt: 'Dima Painter Mision',
          title: 'Dima Painter Mision',
        }}
      />
    </>
  );
};

export default MisionSection;
